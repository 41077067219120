.bgOne{
 
    background-image: url(../img/banner/bg-1.png);
    background-size: cover;
    background-position: bottom;
}
.bgTwo{
 
    background-image: url(../img/banner/bg-2.png);
    background-size: cover;
    background-position: bottom;
}

.bgFour{
 
    background-image: url(../img/banner/bg-4.png);
    background-size: cover;
    background-position: bottom;
}
