.footer {
    color: white;
    background: linear-gradient(360deg, #00012F 0%, #1A1A1A 104%);
    padding-top: 50px;
}

.blod-text {
    font-size: 30px;
    font-weight: 700;

}

.footer p {
    color: #FFFFFF8F;
    font-size: 14px
}

.footer li {
    margin: 16px 20px;
}

.footer li a {
    font-size: 16px;
}

.footer .dark-bg {
    padding-top: 40px;
}

.footer .logo {
    width: 40%;
    margin-bottom: 18px;
    margin-top: 10px;

}

.footer .head {
    font-weight: 600;
    font-size: 20px;
    margin: 4px 20px;
    color: #99A3B8;
}

.copy {
    border-top: 1px solid #99A3B833;
    margin: 50px 0 0;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.copy small {

    font-size: 15px;
}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .footer {

        padding-top: 0px !important;
    }

    .footer .logo {
        margin-bottom: 20px;
        width: 40%;
    }

    .footer .head {
        margin: 10px 0;
    }

    .footer li {
        margin: 16px 0px;
    }

}