.card-college {
    background: #F1F1F1;
    border: 1px solid #CCCCCC;
    margin: 10px;
    @apply p-3 sm:p-6 rounded-xl sm:w-4/5 w-full relative sm:h-[18rem]  h-auto
}

.college-logo {
    background-color: white;
    @apply p-2.5 rounded-xl
}
.college-logo img{
    height: 33px;
}

.card-college .card-head {
    line-height: 26px;
    @apply text-[#00012F] text-[22px]	 font-semibold
}

.card-body {
    float: right;
    @apply sm:w-[70%] w-[100%]
}

.college-img {
    object-fit: cover;
    @apply rounded-xl h-60 relative sm:absolute sm:left-[-20%] left-0  sm:w-[50%] w-full
}