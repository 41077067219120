@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4 {
    font-family: Outfit;
  }

  h5,
  h6,
  p,
  small,
  label {
    font-family: "Poppins", sans-serif;
    @apply text-base;
  }

  .thin-font {
    font-family: Outfit;
    @apply font-thin text-5xl sm:text-6xl text-[#00034D];
  }

  .blod-font {
    font-family: Outfit;
    @apply text-5xl sm:text-6xl font-bold text-[#00034D];
  }

  .section-head {
    font-family: Outfit;
    @apply font-extrabold text-3xl sm:text-4xl text-[#00034D] mb-2 sm:mb-6;
  }

  .sub-pg-title {
    @apply sm:text-2xl py-3 text-xl font-extrabold text-[#00034D];
  }
  .single-line {
    display: -webkit-box !important ;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

@layer components {
  .main-section {
    @apply px-4 py-16 xl:section-p-sm;
  }

  .destinations .main-section {
    @apply px-4 pt-10 pb-0 lg:scroll-section-head;
  }

  .destinations p {
    color: #808080;
  }

  .scroll-section {
    @apply pl-4 py-8 pr-4 sm:scroll-section-sm;
  }

  .btn {
    display: inline-block !important;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    /* padding: 0.575rem 2.5rem; */
    font-size: 14px;
    border-radius: 0.25rem;
    border-radius: 30px;
    text-transform: uppercase;
    border: 1px;
    @apply sm:py-[0.575rem] sm:px-[2.5rem]  py-[0.575rem] px-[2rem];
  }

  .btn-primary {
    color: #fff;
    cursor: pointer;
    display: block;
    position: relative;
    background: linear-gradient(272.88deg, #000261 7.34%, #e10f5a 94.28%);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    font-weight: 500;
  }

  .btn-primary-outline {
    color: #b10c47;
    cursor: pointer;
    display: block;
    position: relative;
    border: 1px solid #b10c47;
    font-weight: 600;
    font-size: 13px;
    background-color: white;
    padding: 0.575rem 2rem;
  }

  .banner-head {
    font-family: Outfit;
    font-weight: 700;
    text-align: left;
    /* font-size: 4.8rem; */
    /* line-height: 80px; */
    @apply text-white sm:text-start sm:text-[4.8rem] text-[3.5rem] sm:leading-[80px] leading-[60px];
  }

  .video-card-gradient {
    background: linear-gradient(180deg, #f46196 0%, #f13176 100%);
  }

  .image-card-p{
    font-family: Outfit;
  }

  .character-img {
    @apply sm:width95vh widthCu hidden lg:block;
  }

  .bg-welcome {
    background-image: url(./img/welcome/bg.png);
    background-size: cover;
  }
  .bg-welcomeTwo {
    background-image: url(./img/welcome/bg-2.png);
    background-size: cover;
  }
  .bg-welcomeThree {
    background-image: url(./img/bg-an.png);
    background-size: cover;
  }
  .gradientDrakOne {
    background: linear-gradient(180deg, #87123c 0%, #5a0c28 100%);
  }

  .gradientDrakTwo {
    background: linear-gradient(357deg, #87123c 0%, #5a0c28 100%);
  }

  .migrate {
    background-image: url(../src/img/dream-bg.png);
    background-size: cover;
  }

  .card-search-more {
    background: linear-gradient(
        267.99deg,
        #1a1a1a -93.04%,
        #00012f 63.9%,
        #87123c 96.19%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    color: white;
    @apply p-6 lg:p-12 rounded-2xl;
  }

  .bg-country {
    background-image: url(../src/img/map.png);
    background-size: cover;
  }

  .small-b-text {
    @apply font-extrabold text-xl sm:text-2xl text-[#1A1A1A] sm:leading-5 sm:mt-1 leading-6;
  }

  .normal-s-text {
    @apply text-[#1A1A1A] text-sm font-medium;
  }

  .laBg {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #dfeee4 100%
    );
  }

  .language-p {
    font-family: Outfit;
    /* font-size: 31.5px; */
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #00034d;
    @apply sm:text-[31.5px] text-4xl;
  }

  .language-p .color {
    color: #e10f5a;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 74%) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 2;
    cursor: pointer;
    z-index: 20;
    @apply block lg:hidden;
  }

  .page-banner {
    background-size: cover;
    background-position: center;
    @apply flex justify-end flex-col text-center py-6 sm:h-[40vh] xl:h-[70vh] h-[30vh] sm:py-16 px-5;
  }

  .bannerExplorePrograms {
    background-image: url(./img/pages/ExplorePrograms/banner.png);
  }
  .bannerServices {
    background-image: url(./img/OurServices-bg.jpg);
  }
  .bannerCareer {
    background-image: url(./img/career-bg.png);
  }

  .bannerDestinations {
    background-image: url(./img/pages/Destinations/1.png);
  }
  .bannerAboutUs {
    background-image: url(./img/About-us-bg.jpg);
  }

  .bannerTestimonial {
    background-image: url(./img/banner/banner-testimonial.svg);
  }

  .bannerFAQ {
    background-image: url(./img/banner/banner-faq.svg);
  }


  .banner-title {
    color: white;
    @apply font-semibold sm:text-5xl text-3xl;
  }

  .country-title {
    @apply font-bold font-medium;
  }

  .small-card {
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.45) 1px 12px 20px -20px;
    @apply px-4 sm:py-10 py-4;
  }
  .small-card img {
    @apply pb-1;
  }
  .small-card small {
    @apply sm:font-semibold  font-medium xl:text-xs text-base;
  }
  .small-head {
    @apply text-lg font-bold  text-[#00012F] py-2;
  }
  .filter-subtitle {
    @apply px-4 py-2 border text-[#00034D] font-bold text-base border-[#CCCCCC]  border-r-0 border-l-0;
  }
  .filter-inputs {
    @apply py-4 px-3;
  }
  .filter-bar {
    border: 1px solid #cccccc;
    border-radius: 20px;
  }
}

@layer utilities {
  .section-p-sm {
    padding: 70px 4rem;
  }

  .scroll-section-sm {
    padding: 0px 0 90px 4rem;
  }

  .scroll-section-head {
    padding: 90px 90px 0 64px;
  }

  .width95vh {
    width: 100vh;
  }

  .widthCu {
    width: 70vh;
    max-width: max-content;
  }
}
