#menu-toggle:checked + #menu {
  display: block;
}
.menu-logo {
  width: 160px;
}

.nav {
  box-shadow: 0 2px 4px -1px #ffffffc2, 0 4px 5px 0 #fff, 0 1px 10px 0 #fff !important;

  @apply lg:px-[1rem] xl:px-[4rem]  px-4;
}
.nav ul a:hover {
  color: #e453a6;
}

.nav ul a {
  font-size: 18px;
  color: #191919;
  display: inline-block;
  font-family: Outfit;
  font-size: 15px;
  padding: 0 20px;
  font-weight: 600;
}

.nav ul li:hover {
  color: var(#e453a6);
}

.nav ul li {
  padding: 0px;
}

nav ul li a:hover {
  color: var(#e453a6) !important;
}

.nav ul li .active::after {
  content: "";
  display: block;
  border-bottom: 4px solid #e453a6;
  border-radius: 20px;
  width: 100%;
  margin-top: 1px;
}

.navicon {
  width: 1.125em;
  height: 0.125em;
  background-color: #0d0017;
}

.navicon::before,
.navicon::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  content: "";
  background: #000000;
}

.navicon::before {
  top: 5px;
}

.navicon::after {
  top: -5px;
}

.menu-btn:not(:checked) ~ .menu {
  display: none;
}

.menu-btn:checked ~ .menu {
  display: block;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon::before {
  transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon::after {
  transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon .navicon::before,
.menu-btn:checked ~ .menu-icon .navicon::after {
  top: 0;
}

.close-btn {
  background-color: white;
  border-radius: 40px;
  margin-bottom: 6px;
}

.filterCssOp {
  filter: contrast(40%) brightness(80%);
}

.filterCssFull {
  filter: contrast(500%) brightness(550%);
}

.transition-transform {
  transition-timing-function: ease-in-out;
  height: 100%;
}

.transform-o {
  transition-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.bg-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1c042f;
}

.loader-icon {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -2px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background-color: #0d0017;
  z-index: 100;
  padding-top: 150px;
}

.loader .text-data {
  color: rgb(220, 220, 220);
  z-index: 200;
  padding-top: 25px;
  font-weight: 600;
}

.loader-img {
  position: relative;
}

.loader img {
  z-index: 200;
  opacity: 0.2;
}

.testimonial-card video {
  width: 100%;
  height: 250px;
  border-radius: 40px;
  display: block;
  padding: 10px;
}

@media (max-width: 1200px) {
  .nav-sm {
    flex-direction: row-reverse;
    height: 100vh;
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.7) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.73);
  }

  .nav-sm ul li {
    color: #eaeaea;
    border-bottom: none;
    text-align: center;
    font-size: 18px;
    padding: 10px;
  }

  .nav-sm ul li a {
    font-size: 28px;
    color: white;
  }

  .nav-sm .nav-menu {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 13px;
  }

  .nav img {
    width: 150px;
  }

  .hide-sm {
    display: none !important;
  }

  .nav-menu {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: #ffffff7d;
    border: 1px solid #ffffff4d;
    border-radius: 12px;
    box-shadow: 0 4px 30px #0000001a;
    padding: 3px;
  }
}

@media (max-width: 1024px) {
  .footer-head img {
    width: 180px !important;
  }
  .footer .dark-bg {
    padding-top: 12px !important;
  }
  .footer {
    padding-top: 10px !important;
  }
}

@media (max-width: 1280px) {
  .footer-head img {
    width: 180px !important;
  }
}


@media (min-width: 1200px) and (max-width: 1390px) {
   .nav{
    padding-left: 12px !important;
    padding-right: 12px !important;
   }
   .nav-list-ul li a{
    padding-left: 14px !important;
    padding-right: 14px !important;
   }
}