.slick-arrow {
  bottom: -80px !important;
  top: -60px !important;
  border: 1px solid #3D3F8F !important;
  padding: 25px !important;
  border-radius: 30px !important;
  margin: 10px;
  width: 25px !important;
  height: 25px !important;



}

.slick-track {
  /* margin-top: 20px; */
  @apply sm:mt-5 mt-0
}

.slick-prev {
  left: auto !important;
  right: 150px !important;
  background-color: #3D3F8F !important;

}

.slick-prev:before {
  font-family: 'Material Icons' !important;
  content: 'chevron_left' !important;
  color: rgb(255, 255, 255) !important;
}


.slick-next {
  background-color: #3D3F8F !important;

}

.slick-next:before {
  font-family: 'Material Icons' !important;
  content: 'chevron_right' !important;
  color: rgb(255, 255, 255) !important;

}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}

.slick-next {
  right: 60px !important;
  left: auto !important;
}

.slick-disabled {
  background-color: #ffffff !important;

}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #3d3f8f !important;
  background-color: #ffffff !important;

}
.cards-container {
  display: flex;
  border-radius: 6px;
  color: #333;
  background: #eee;
  padding: 1rem;
  box-shadow: 0 0 1rem #000 inset;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  flex: 1 0 250px;
  background: #fff;
  margin: 8px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  box-shadow: rgb(0 0 0) 0px 13px 20px -20px;

  img {
    width: 400px !important;
    height: 450px;
    object-fit: cover;
  }

  &:hover {
    transform: translateY(-3px);
  }
}

.card-content {
  padding: 1rem;
}
.card-Width{
  height: 125px !important;
}

.testimonial_video_slider .slick-arrow {
  display: none !important;
}

@media screen and (max-width: 625px) {
  .container {
    width: 100%
  }

  .cards-container {
    padding: .5rem;
  }

  .card {
    margin: .5rem;
  }

  .card { 
    img{
      height: 320px;
    }
  }

  .slick-next {
    right: 30% !important;
    left: auto !important;
  }

  .slick-prev {
    right: 50% !important;

  }

}