

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-item {
    margin: 15px 0;

}

.simple-accordion-item p {
    color: #7A7A86;
    padding: 10px 0;
}

.simple-accordion-item {
    border-bottom: 1px solid #ebebeb;
    padding: 15px 0;
    cursor: pointer;
}
.simple-accordion-item  h3{
  color: #121127;
}
.pink-card{ 
  background: linear-gradient(131.99deg, #F46196 5.95%, #87123C 95.68%);
  border-radius: 16px;
  @apply p-5 sm:p-6 flex-col justify-end  flex items-center
}
.slider-pink-card .pink-card {
  cursor: pointer;
}
.slider-pink-card  img{
  width: 80px !important;
}
 .slider-pink-card .pink-card {
  cursor: pointer;
}
.slider-pink-card .pink-card:hover {
  background: linear-gradient(131.99deg, #fa78a8 5.95%, #cc0b52 95.68%);


}
.pink-card img{
  width: fit-content;
}

.white-card{ 
  background: white;
  border-radius: 16px;
  @apply p-5 sm:p-6 gap-3 justify-start  flex items-center
}
.white-card img{
  width: 43px;
  
}
.white-card-icon{
  background: white;
  border-radius: 16px;
  @apply p-5 sm:p-6 gap-3 justify-start  flex flex-col
}
.white-card-icon .icon-bg{
  background: linear-gradient(131.99deg, #F46196 5.95%, #87123C 95.68%);
  padding: 10px;
  border-radius: 8px;

}

  .marquee {
    display: flex;
    block-size: var(--marquee-item-height);
    margin-block: 30px;
    position: relative;
    overflow-x: hidden;
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
    align-items: center;
  }
  
  .marquee--8 {
    --marquee-item-width: 100px;
    --marquee-item-height: 80px !important;
    --marquee-duration: 36s;
    --marquee-items: n;
  }
  
  .marquee__item {
    --marquee-item-offset: max(
      calc(var(--marquee-item-width) * var(--marquee-items)),
      calc(100% + var(--marquee-item-width))
    );
    --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items) - var(--marquee-item-index)) * -1);
    position: absolute;
    inset-inline-start: var(--marquee-item-offset);
    transform: translateX(-50%);
    animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
    width: 80px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  
  .marquee__item:nth-child(4n) {
    border-top-right-radius: 1rem;
  }
  
  .marquee__item:nth-child(4n + 1) {
    border-bottom-right-radius: 1rem;
  }
  
  .marquee__item:nth-child(4n + 2) {
    border-bottom-left-radius: 1rem;
  }
  
  .marquee__item:nth-child(4n + 3) {
    border-top-left-radius: 1rem;
  }
  
  .marquee__item:nth-of-type(n) {
    --marquee-item-index: n;
  }
  
  


  
  

  
  @keyframes go {
    to {  
      inset-inline-start: calc(var(--marquee-item-width) * -1);
    }
  }


  .bgWelcome{
 
    background-image: url(../src/img/welcome/1.png);
    background-size: cover;
    background-position: bottom;
}




.card-contact {
  /* padding: 30px; */
  border: 1px solid #f7f7f7;
  border-radius: 20px;
  /* margin: 40px auto; */
  width: 70%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-top: 20px; */
  margin: 0 auto !important;
  @apply p-[10px] sm:p-[30px] sm:mx-[40px] mx-[auto] w-[95%] sm:w-[70%] mt-[100px] 
}
.card-contact-full {
  width: 100%;
  @apply sm:px-[30px] px-4

}

.card-contact h4 {
  /* font-size: 2rem; */
  font-weight: 600;
  line-height: 0.1;
  @apply sm:text-[2rem] text-[1.5rem] 
}


.Whats {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

